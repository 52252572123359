a {
  text-decoration: none;
}

.error-left {
  width: 50%;
  /* background-color: lightsalmon; */
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.error-right {
  width: 50%;
  min-height: 100vh;
  background-image: url('../../images/error-003.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
}

.center404 {
  width: 50%;
  min-height: 100vh;
  background-image: url('../../images/error-404.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: contain;
  z-index: 11;
  margin: auto;
}

.center404Letters {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-weight: 700; */
  font-size: 40px;
  height: 100%;
  justify-content: center;
}

.span404 {
  font-size: 120px;
  font-weight: 600;
}

.spanPage {
  font-size: 40px;
  font-weight: 600;
}

@media (max-width: 800px) {
  .error-right {
    width: 0;
    display: none;
  }
  .error-left {
    width: 100%;

    /* display: flex; */
  }
  .bottom {
    margin-top: 30px;
    height: 100% !important;
  }
}

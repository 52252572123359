.back-btn {
  width: 40px !important;
  float: left;
  margin-left: 35px !important;

  height: 45px;
  margin-top: 30px !important;
}
.link-btn {
  /* margin-left: 40px !important; */
}

.menu-left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.menu-right {
  width: 50%;
  min-height: 100vh;
  background-image: url('../../images/home-001.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
}

.menu-image {
  width: 100%;
  min-height: 30vh;
  background-image: url('../../images/menu1-001.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
}

.item-list {
  width: 40vw;
  margin-top: 30px;
}

.item-box {
  border: 2px solid grey;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.quantidade {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.categoria-titulo {
  /* display: flex; */
  align-items: center;
  text-align: start !important;
  padding-bottom: 30px !important;
}
.categoria-titulo h2 {
  /* display: flex; */

  padding-bottom: 0px;
  margin-bottom: 0px;
}
.categoria-titulo p {
  font-size: 10px;
}

.btn-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.nome {
  text-align: left;
  /* padding-right: 10px; */
}

/* .menu-image img {
  width: 100%;
  min-height: 100vh;
  background-image: url('../../images/error-003.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
} */

@media (max-width: 800px) {
  .menu-right {
    width: 0;
    display: none;
  }
  .menu-left {
    width: 100%;

    /* display: flex; */
  }
  .item-list {
    width: 80vw;
  }
  /* .bottom {
      margin-top: 30px;
      height: 100% !important;
    } */
}

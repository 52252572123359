.quantidadeConfirmada {
  margin-right: 20px;
}

.resumo-list {
  border: 2px solid grey;
  border-radius: 10px;
  /* height: 40px; */
  /* display: block; */
  text-align: left;
  /* align-items: center;
  flex-direction: column; */
  width: 40vw;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.resumo-item {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .menu-right {
    width: 0;
    display: none;
  }
  .menu-left {
    width: 100%;

    /* display: flex; */
  }
  .resumo-list {
    width: 80vw;
  }
  /* .bottom {
      margin-top: 30px;
      height: 100% !important;
    } */
}
